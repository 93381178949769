import { useState } from "react";
import Layout from "../components/layout";
import { aboutImages, whatWeDo } from "../utils/data";
// @ts-ignore
import { IKImage, IKContext } from 'imagekitio-react'
import RightIcon from '../assets/icons/right.svg'
import LeftIcon from '../assets/icons/left.svg'
import CloseIcon from '../assets/icons/close-gallery.svg'
import BralnGif from '../assets/images/braln.gif'

const LandingPage = () => {
  const [lightboxDisplay, setLightBoxDisplay] = useState(false)
  const [imageToShow, setImageToShow] = useState("")

  const showImage = (image: any) => {
    //set imageToShow to be the one that's been clicked on    
    setImageToShow(image);
    //set lightbox visibility to true
    setLightBoxDisplay(true);
    document.body.classList.add('overflow-y-hidden');
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false)
    document.body.classList.remove('overflow-y-hidden');
  }

  const showNext = (e: any) => {
    e.stopPropagation()
    let currentIndex = aboutImages.indexOf(imageToShow)
    if (currentIndex >= aboutImages.length - 1) {
      let nextImage = aboutImages[0]
      setImageToShow(nextImage)
    }
    else {
      let nextImage = aboutImages[currentIndex + 1]
      setImageToShow(nextImage)
    }
  }

  const showPrev = (e: any) => {
    e.stopPropagation()
    let currentIndex = aboutImages.indexOf(imageToShow)
    if (currentIndex <= 0) {
      let nextImage = aboutImages[aboutImages.length - 1]
      setImageToShow(nextImage)
    }
    else {
      let nextImage = aboutImages[currentIndex - 1]
      setImageToShow(nextImage)
    }
  }
  return (
    <Layout navBarColor="bg-[#233275]">
      {lightboxDisplay ?
        <div id="lightbox" className="z-50 fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/80">

          {/* previous button */}
          <img src={LeftIcon} alt="" className="ml-10 h-5 w-5 mr-10 cursor-pointer hidden lg:block" onClick={showPrev} />
          {/* <button className="text-white text-5xl mr-10 hidden lg:block" onClick={showPrev}>⭠</button> */}
          <div className="relative w-4/5 md:w-[50%]">
            {/* <img id="lightbox-img" className="object-contain w-full border border-red-500" src={imageToShow} alt="" /> */}
            <div>
              <IKContext urlEndpoint="https://ik.imagekit.io/braln">
                <IKImage path={imageToShow} />
              </IKContext>
            </div>
            {/* close button */}
            <img src={CloseIcon} alt="" className="mr-10 h-5 w-5 cursor-pointer absolute -top-12 -right-10 md:-right-20" onClick={hideLightBox} />

            <div className="flex items-center justify-center mt-10 lg:hidden">
              {/* previous button */}
              <img src={LeftIcon} alt="" className="ml-10 h-5 w-5 mr-10 cursor-pointer" onClick={showPrev} />

              {/* <button className="text-white text-5xl mr-10" onClick={showPrev}>⭠</button> */}
              {/* next button */}
              <img src={RightIcon} alt="" className=" h-5 w-5 cursor-pointer ml-10" onClick={showNext} />
            </div>


          </div>
          {/* next button */}
          <img src={RightIcon} alt="" className="ml-10 h-5 w-5 cursor-pointer hidden lg:block" onClick={showNext} />
        </div>
        : ''}
      {/* hero section */}
      <section className="bg-[#233275] text-white mt-[50px] pt-[58px] pb-[68px] md:h-[80vh] lg:h-[100vh] lg:pt-10">
        <div className="layout-container">
          <div className="text-center md:text-left md:flex md:items-end md:space-x-4 lg:space-x-10">
            <h1 className="text-2xl leading-[29px] font-semibold mb-4 sm:text-6xl sm:text-[64px] lg:leading-[77px] md:flex-2 md:mb-0"><span className="md:block">Creating</span> <span className="md:block">delightful</span> digital products</h1>
            <p className="font-light text-base leading-5 sm:text-lg lg:text-2xl md:flex-1">Braln is a technology innovation hub where we design and build products that solves real life problems.</p>
          </div>
        </div>
      </section>

      <section className="layout-container mt-[33px] md:-mt-[20vh] lg:-mt-[45vh]">
        <div className="md:flex md:space-x-5">
          {
            whatWeDo.map((item, index) => (
              <div key={index} className={`py-10 rounded-[15px] px-4 text-center mb-5 flex-1 ${item.bgColor} md:text-left`}>
                <h3 className="text-[#233275] text-xl leading-6 font-semibold sm:text-2xl sm:leading-[29px] md:w-4/5">{item.title}</h3>
                <div className="md:h-[300px] flex items-center justify-center">
                  <img src={item.imgSrc} alt="" className="my-[35px] w-[60%] mx-auto md:my-0 md:mt-0 md:mb-0 md:mx-0 md:w-4/5" />
                </div>
                <p className="text-[#282938] text-sm sm:text-base sm:leading-[26px]">{item.description}</p>
              </div>
            ))
          }
        </div>
      </section>

      {/* <div className="text-white pb-[26px] bg-black md:pb-[148px]">
            <Nav noBackgroundColor />
            <div className="layout-container">
                <div className="md:w-4/5">
                    <h1 className="font-bold text-[23px] leading-[30px] text-center mb-4 mt-[26px] md:text-5xl md:leading-[62px] md:text-left">Transforming businesses to compete for tomorrow </h1>
                    <p className="text-sm leading-[18px] mb-10 text-center md:text-left md:text-xl md:leading-[26px] md:w-[90%]">We are a client centric software development studio and IT consultancy hub. We create immersive and business applications that enhances your digital strategy.</p>
                    <Button title="Contact us" href="/contact" className="mx-auto md:mx-0 bg-white text-primary" />
                </div>
            </div>
        </div> */}
      {/* <Hero /> */}

      {/* who we are */}
      <section className="layout-container mt-[65px] mb-[58px]">
        <div className="mb-[45px]">
          <h1 className="text-[#282938] text-2xl leading-[29px] font-semibold md:text-[64px] md:leading-[77px]">Who we are</h1>
          <p className="font-light text-sm leading-[18px] text-[#111]/[0.79] my-[10px] md:text-[28px] md:leading-[38px] md:w-[75%]">We are a enthusiastic team that love what we do and we are passionate to bring about growth using the latest technology.</p>
          <a href="/about" className="underline text-sm leading-[38px] font-normal md:text-[28px] md:leading-[38px]">About Braln</a>
        </div>
        {/* grid grid-cols-2 grid-rows-6 gallery gap-x-5 gap-y-5 lg:grid-cols-3 lg:gap-x-[61px] lg:gap-y-[54px] */}
        <div className="grid grid-cols-2 gap-x-5 gap-y-5 lg:gap-y-0 lg:gap-x-10 grid-rows-1 gallery lg:grid-cols-3">
          {
            aboutImages.map((image, index) => (
              <div key={index} onClick={() => showImage(image)}>
                <IKContext urlEndpoint="https://ik.imagekit.io/braln">
                  <IKImage path={image}/>
                </IKContext>
                {/* <img src={image} className=" w-full object-contain" alt="" onClick={() => showImage(image)} /> */}
              </div>
            ))
          }
        </div>
      </section>

      {/* Why Braln */}
      <section className="bg-[#FBFBFD] pt-[52px] pb-[46px] mt-[58px]">
        <div className="layout-container">
          <h1 className="text-[#264DE4] text-2xl mb-[42px] leading-[29px] font-semibold text-center sm:text-5xl sm:leading-[58px]">Why Braln?</h1>
          <div className="md:grid grid-cols-[1fr_2fr_1fr] md:items-center">
            <div className="text-center md:text-left">
              <h1 className="text-[#282938] text-xl leading-6 font-semibold mb-[10px] sm:text-3xl lg:text-4xl lg:leading-[43px] ">Goal focused</h1>
              <p className="font-light text-sm leading-7 text-[#111111]/[0.79] sm:text-base lg:text-xl">We are a client centric software development studio and IT consultancy hub. We create immersive and business applications that enhances your digital strategy.</p>
            </div>

            <div className="my-[42px]">
              <img src={BralnGif} alt="" className='mx-auto w-4/5' />
            </div>

            <div className="text-center md:text-right">
              <h1 className="text-[#282938] text-xl leading-6 font-semibold mb-[10px] sm:text-3xl lg:text-4xl lg:leading-[43px]">Continuous improvement</h1>
              <p className="font-light text-sm leading-7 text-[#111111]/[0.79] sm:text-base lg:text-xl">
              Braln enables individuals with the technological innovation to save time, accomplish more, develop and have an effect. Get the unfair advantage.
                {/* We are a client centric software development studio and IT consultancy hub. We create immersive and business applications that enhances your digital strategy. */}
                </p>
            </div>
          </div>
        </div>

      </section>
      {/* <Testimonial /> */}
    </Layout>
  );
};

export default LandingPage;
