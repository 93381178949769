import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import AboutPage from '../pages/AboutPage';
import ContactPage from '../pages/ContactPage';
import LandingPage from '../pages/LandingPage';
import NotFound from '../pages/NotFound';
import Portfolio from '../pages/Portfolio';


const AppRouter = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
};

export default AppRouter;
