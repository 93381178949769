import Layout from '../components/layout';
// @ts-ignore
import { IKImage, IKContext } from 'imagekitio-react'
import { specialization, team } from '../utils/data';

const AboutPage = () => {
    return (
        <Layout navBarColor="bg-[#F4F6FC]">
            {/* hero */}
            <div className="bg-[#F4F6FC] mt-[50px] pt-[58px] pb-[72px] md:pt-[91px] md:pb-[119px]">
                <div className="layout-container text-center md:text-left">
                    <h1 className="text-lg leading-[22px] font-bold text-black mb-4 md:text-[96px] md:leading-[115px]">We are Braln</h1>
                    <p className="text-base text-[#333] font-light md:text-[28px] md:leading-[38px] md:w-[75%]">We are a technology innovation hub. We create immersive and business applications that enhance your digital strategy.</p>
                </div>
            </div>
            {/* What we do */}
            <section className="layout-container pt-[33px] pb-12 md:pt-[96px]">
                <h1 className="mb-6 text-[#282938] font-semibold text-[28px] leading-[34px] text-center md:text-left md:mb-[96px]">What we do</h1>
                <div>
                    <div className="mb-6 md:mb-[99px] md:grid grid-cols-2 md:gap-x-[20px] lg:gap-x-[30px] md:items-center">
                        <div className="text-center mb-4 md:text-left">
                            <h2 className="text-xl leading-6 text-[#282938] font-semibold mb-3 sm:text-4xl lg:text-[52px] lg:leading-[62px]"><span className="md:block">Passionate,</span> Excellent</h2>
                            <p className='font-light text-base leading-[19px] text-[#111]/[0.79] sm:text-lg lg:text-[28px] lg:leading-[38px]'>
                                We are a team that do not compromise on quality. Our team is made up of experienced professionals with proven industry track record.
                                {/* We are a client centric software development studio and IT consultancy hub. We create immersive and business applications that enhances your digital strategy. */}
                                </p>
                        </div>
                        <div>
                            <IKContext urlEndpoint="https://ik.imagekit.io/braln">
                                <IKImage path='/staff.jpg' />
                            </IKContext>
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 md:gap-x-[20px] lg:gap-x-[30px] md:items-center">
                        <div className="text-center mb-4 md:text-left md:order-2">
                            <h2 className="text-xl leading-6 text-[#282938] font-semibold mb-3 sm:text-4xl lg:text-[52px] lg:leading-[62px]"><span className="md:block">Inspire,</span> Innovate, Impact</h2>
                            <p className='font-light text-base leading-[19px] text-[#111]/[0.79] sm:text-lg lg:text-[28px] lg:leading-[38px]'>We are a client centric software development studio and IT consultancy hub. We create immersive and business applications that enhances your digital strategy.</p>
                        </div>
                        <div className="md:order-1">
                            <IKContext urlEndpoint="https://ik.imagekit.io/braln">
                                <IKImage path='/office.jpg' />
                            </IKContext>
                        </div>
                    </div>
                </div>
            </section>

            {/* Specialization */}

            <section className="bg-[#F4F6FC]/[0.7] pt-[43px] pb-[53px]">
                <div className="layout-container">
                    <h1 className="text-[#282938] text-2xl leading-[29px] font-semibold mb-[34px] sm:text-4xl sm:leading-[43px]">Specialization</h1>
                    <div className="grid grid-cols-2 gap-y-10 gap-x-5 md:grid-cols-4">
                        {specialization.map((item, index) => (
                            <div key={index}>
                                <h3 className="text-[#282938] font-semibold mb-[10px] text-lg leading-[22px] sm:text-2xl sm:leading-[29px]">{item.title}</h3>
                                <ul className="flex flex-col space-y-2">
                                    {item.list.map((listItem, index) => (
                                        <li key={index} className="text-sm leading-[17px] text-[#434242] sm:text-base lg:text-lg lg:leading-[22px]">{listItem}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            {/* Meet our team */}
            <section className="layout-container pt-[51px] pb-[46px] md:pt-[120px] md:pb-[122px]">
                <h1 className="text-lg leading-[64px] font-medium text-center md:text-5xl md:leading-[64px]">Meet Our Team</h1>
                <div className="grid grid-cols-2 gap-y-[18px] mt-[18px] md:grid-cols-3 md:gap-y-10 md:mt-12">
                    {
                        team.map((item, index) => (
                            <div key={index}>
                                <img src={item.avatar} alt="" className="h-16 w-16 rounded-full object-cover mx-auto mb-[10px] md:h-[168px] md:w-[168px] md:mb-[30px]" />
                                <h1 className="font-medium text-base text-[#1D2130] text-center md:text-2xl md:leading-9">{item.name}</h1>
                                <p className="text-[#1D2130]/70 text-base leading-7 text-center md:text-base md:leading-7">{item.position}</p>
                            </div>
                        ))
                    }
                </div>
            </section>

        </Layout>
    )
};

export default AboutPage;
