import TransparentLogo from '../../assets/nav/logo-transparent.svg';
import Logo from '../../assets/nav/logo.svg';
import { navLinkList } from '../../utils/data';
import { NavProps } from '../../utils/typings';
import Menu from '../../assets/icons/hamburger.png'
import DarkMenu from '../../assets/icons/hamburger-dark.png'
import { useState } from 'react';
import SideBar from './SideBar';
import Button from '../input/button';
import { NavLink, useLocation } from 'react-router-dom';

const Nav = ({ navBarColor }: NavProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const { pathname } = useLocation();

    return (
        <div>
            <SideBar isOpen={isOpen} setIsOpen={setIsOpen} toggleMenu={toggleMenu} />
            <nav className={`${navBarColor ? `${navBarColor}` : "bg-white"} py-2 fixed top-0 left-0 min-h-[50px] w-full z-50`}>
                <div className="layout-container">
                    <div className="flex items-center justify-between">
                        <div>
                            <a href="/">
                                <img src={pathname === '/' ? Logo : TransparentLogo} className="h-[34px] w-[56px] object-cover md:h-[54px] md:w-[89px]" alt="" />
                            </a>
                        </div>
                        <div className="hidden md:inline-flex items-center space-x-7">
                            {navLinkList.map((item, index) => (
                                item.name.toLowerCase() === 'contact us' ? <Button key={index} href={item.path} title={item.name} primary className="w-[108px] h-[47px]" /> : <NavLink key={index} className={({ isActive }) => (isActive ? `${pathname === '/' ? 'text-white' : 'text-[#111]'} inline-block text-base leading-[21px] after:content-[''] after:block after:w-0 after:border-b-2 after:border-white hover:after:w-full hover:after:transition hover:after:duration-[0.3]` : `${pathname === '/' ? 'text-white/70' : 'text-[#6D6D6D]/90'} inline-block text-base leading-[21px] after:content-[''] after:block after:w-0 after:border-b-2 after:border-white hover:after:w-full hover:after:transition hover:after:duration-[0.3]`)} to={item.path}>{item.name}</NavLink>
                            ))}
                        </div>
                        <div className="md:hidden" onClick={toggleMenu}>
                            <img src={pathname === '/' ? Menu : DarkMenu} className="h-3 w-4" alt="" />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        // <div>
        //     <SideBar isOpen={isOpen} setIsOpen={setIsOpen} toggleMenu={toggleMenu} />
        //     <nav className={`${noBackgroundColor ? 'transparent' : 'bg-primary'} py-2 fixed top-0 left-0 w-full z-50 bg-white`}>
        //         <div className="layout-container">
        //             <div className="flex items-center justify-between">
        //                 <div>
        //                     <a href="/">
        //                         <img src={Logo} className="h-[34px] w-[56px] object-cover md:h-[54px] md:w-[89px]" alt="" />
        //                     </a>
        //                 </div>
        //                 <div className="hidden md:inline-flex items-center space-x-7">
        //                     {navLinkList.map((item, index) => (
        //                         item.name.toLowerCase() === 'contact us' ? <Button href={item.path} title={item.name} primary className="w-[108px] h-[47px]" /> : <a key={index} className=" text-white inline-block text-base leading-[21px] after:content-[''] after:block after:w-0 after:border-b-2 after:border-white hover:after:w-full hover:after:transition hover:after:duration-[0.3]" href={item.path}>{item.name}</a>

        //                     ))}
        //                 </div>
        //                 <div className="md:hidden" onClick={toggleMenu}>
        //                     <img src={Menu} alt="" />
        //                 </div>
        //             </div>
        //         </div>
        //     </nav>
        // </div>
    )
};

export default Nav;
