import { useRef } from "react";
import Logo from "../../assets/nav/logo-transparent.svg"
import Close from "../../assets/icons/close.png"
import { navLinkList } from "../../utils/data";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside.";
import { SideBarProps } from "../../utils/typings";

const SideBar = ({ isOpen, toggleMenu, setIsOpen }: SideBarProps) => {
    const sideBarRef = useRef(null);

    const clickOutsideHandler = () => {
        setIsOpen(false);
    }

    useOnClickOutside(sideBarRef, clickOutsideHandler);


    return isOpen ? (
        <div className={`bg-black/80 w-full fixed h-screen top-0 left-0 z-[200] overflow-y-hidden md:hidden`}>
            <aside ref={sideBarRef} className={`bg-white pb-5 pl-5 pt-[17px] pr-[33px] w-3/5 fixed top-0 overflow-hidden z-[9999] h-screen ${isOpen ? "right-0 transition-all ease-in-out "
            : "right-[-100%] transition-all ease-in-out"}`}>
                <div className="flex justify-between items-center mb-[45px]">
                    <img src={Logo} className="object-cover w-[63px] h-10" alt="" />
                    <img src={Close} alt="" onClick={toggleMenu} />
                </div>
                <ul>
                    {navLinkList.map((item, index) => (
                        <li key={index} className="mb-[38px]">
                            <a href={item.path} className="text-[#111111] text-base leading-[21px]">{item.name}</a>
                        </li>
                    ))}
                </ul>
            </aside>
        </div>
    ) : null
};

export default SideBar;
