import { LayoutProps } from '../../utils/typings';
import Footer from './footer';
import Nav from './nav';



const Layout = ({ navBarColor, children }: LayoutProps) => {

    return (
        <>
            <Nav navBarColor={navBarColor} />
            {children}
            <Footer />
        </>
    );
};

export default Layout;
