import Logo from "../../assets/footer/braln.svg"
import { contactDetails, footerLinkList, socialFooterLinks } from "../../utils/data";


const Footer = () => {
    const contactDetailsLength = contactDetails.length;
    return (
        <footer className='bg-[#131416] py-10 text-white sm:pt-[43px]'>
            <div className="layout-container">
                <div className="grid grid-cols-[2fr_1fr] items-start">
                    <div    >
                        <div>
                            <h1 className="font-semibold text-2xl leading-[29px] sm:text-5xl sm:leading-[58px]">Let’s talk</h1>
                            <a href="/contact" className="font-normal text-sm leading-7 mt-[9px] mb-[55px] block sm:text-xl sm:mt-6">Contact Us</a>
                        </div>
                        <div className="md:grid grid-cols-2">
                            <div className="flex flex-col space-y-5">
                                {contactDetails.map((item, index) => (
                                    <div key={index} className={`flex space-x-2 ${index === contactDetailsLength - 1 ? 'items-start' : 'items-center'}`}>
                                        <img src={item.icon} alt="" />
                                        <p className="text-base leading-7 font-normal text-opacity-80">{item.value}</p>
                                    </div>
                                ))}
                            </div>
                            {/* social links on mobile */}
                            <div className="flex md:hidden">
                                <div className="md:ml-auto">
                                    <ul className="mt-8">
                                        {footerLinkList.map((item, index) => (
                                            <li key={index}>
                                                <a className={`text-sm leading-[26px] capitalize block sm:text-base sm:leading-[26px] ${index === footerLinkList.length - 1 ? 'mb-0' : 'mb-[10px]'}`} href={item.path}>{item.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                    <div>
                                        <img src={Logo} alt="" className="mt-5 mb-[7px] md:mt-10" />
                                        <ul
                                            className="flex space-x-5">
                                            {socialFooterLinks.map((item, index) => (
                                                <li key={index}>
                                                    <a href={item.path}>
                                                        <img src={item.icon} alt="" />
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* social links on desktop */}
                    <div className="hidden md:flex">
                        <div className="">
                            <ul className="mt-8">
                                {footerLinkList.map((item, index) => (
                                    <li key={index}>
                                        <a className={`text-sm leading-[26px] capitalize block sm:text-base sm:leading-[26px] ${index === footerLinkList.length - 1 ? 'mb-0' : 'mb-[10px]'}`} href={item.path}>{item.name}</a>
                                    </li>
                                ))}
                            </ul>
                            <div>
                                <img src={Logo} alt="" className="mt-5 mb-[7px] md:mt-10" />
                                <ul
                                    className="flex space-x-5">
                                    {socialFooterLinks.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.path}>
                                                <img src={item.icon} alt="" />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="border-t-[0.28px] border-white/[0.2] mt-[60px]">
                    <p className="flex justify-center mt-[30px] text-[13px] leading-6 sm:text-sm sm:leading-6">© 2022 Braln. All rights reserved</p>
                </div>
            </div>
        </footer>
        // <footer className="bg-[#F4F4F3]  py-5">
        //     <div className="layout-container">
        //         <div className="flex justify-center sm:border-b-[0.47px] sm:border-[#111]/[0.2]">
        //             <div className="border-b-[0.47px] border-[#111]/[0.2] sm:border-none">
        //                 <div className="flex justify-center">
        //                     <img src={Logo} alt="" />
        //                 </div>
        //                 <ul className="mt-8 sm:flex sm:space-x-5">
        //                     {footerLinkList.map((item, index) => (
        //                         <li key={index}>
        //                             <a className="text-[#111] text-sm leading-[26px] text-center block mb-6" href={item.path}>{item.name}</a>
        //                         </li>
        //                     ))}
        //                 </ul>
        //             </div>

        //         </div>
        //         <div className="mt-10 sm:flex sm:items-center sm:justify-between sm:mt-[25px]">
        //             <ul
        //             className="order-2 flex space-x-5 justify-center">
        //                 {socialFooterLinks.map((item, index) => (
        //                     <li key={index}>
        //                         <a href={item.path}>
        //                             <img src={item.icon} alt="" />
        //                         </a>
        //                     </li>
        //                 ))}
        //             </ul>
        //             <p className="flex justify-center mt-3 text-[#111] text-sm leading-6 order-1 sm:mt-0">© 2022 Braln. All rights reserved</p>

        //         </div>
        //     </div>
        // </footer>
    )
};

export default Footer;



