import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Layout from '../components/layout';
import TextInput from '../components/input/textInput';
import TextArea from '../components/input/textArea';
import Button from '../components/input/button';
import { toast } from 'react-toastify';


const ContactPage = () => {

    const initialFormData = {
        name: '',
        email: '',
        subject: '',
        message: '',
    }

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false)
    const form = useRef<HTMLFormElement>(null)

    const handleTextChange = (event: React.FormEvent<HTMLTextAreaElement> | React.FormEvent<HTMLInputElement>): void => {
        setFormData({ ...formData, [event.currentTarget.name]: event.currentTarget.value });
    }

    useEffect(() => {
        if (formData.email && formData.message && formData.name && formData.subject) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }

    }, [formData.email, formData.message, formData.name, formData.subject])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
        if (form.current) {
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID!, process.env.REACT_APP_TEMPLATE_ID!, form.current, process.env.REACT_APP_PUBLIC_KEY)
                .then((result) => {
                    if (result) {
                        setIsLoading(false)
                        setFormData(initialFormData)
                        toast.success('Thanks for reaching out to us. Expect a reply soon')
                    } else {
                        setIsLoading(false)
                        toast.error('Oops! Something went wrong.')
                    }
                }, (error) => {
                    setIsLoading(false)
                    toast.error('Oops! Something went wrong.')
                });
        }
    };


    return (
        <Layout>
            <section className="bg-[#F4F6FC] mb-12 py-5 md:py-[50px] md:mb-[85px] md:w-[70%] md:mx-auto md:rounded-[20px] mt-12 sm:mt-[93px]">
                <h1 className="font-bold text-lg mb-8 sm:text-[28px] sm:leading-[28px] text-center">We'd Love To Hear From You</h1>

                <form ref={form} className="w-4/5 mx-auto" onSubmit={handleSubmit}>
                    <div className="md:flex">
                        <TextInput placeHolder='Enter your full name' type="text" value={formData.name} name='name' handleTextChange={handleTextChange} className="border-[#000]/20 placeholder-[#636363] text-[#636363] contact-page-input" />
                        <TextInput placeHolder='Enter your Email' type="email" value={formData.email} name='email' handleTextChange={handleTextChange} className="my-8 border-[#000]/20 placeholder-[#636363] text-[#636363] md:my-0 md:ml-8 contact-page-input" />
                    </div>
                    <TextInput placeHolder='Enter your subject' type="text" value={formData.subject} name='subject' handleTextChange={handleTextChange} className="border-[#000]/20 placeholder-[#636363] text-[#636363] md:mt-8 contact-page-input" />
                    <TextArea type='text' placeHolder="Write your  question here" value={formData.message} name="message" handleTextChange={handleTextChange} className="my-8 border-[#000]/20 placeholder-[#636363] text-[#636363] contact-page-input" />
                    <Button isLoading={isLoading} isDisabled={!isDisabled} title="Submit" primary className="mx-auto disabled:bg-gray-500" />
                </form>
            </section>
        </Layout>
    )
};

export default ContactPage;
