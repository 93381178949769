import Instagram from "../assets/footer/instagram.svg";
import Dribble from "../assets/footer/dribble.svg";
import Twitter from "../assets/footer/twitter.svg";
import YouTube from "../assets/footer/youtube.svg";
import CEO from "../assets/images/user.png";
import Ekene from "../assets/images/ekene.jpg";
import Udeme from "../assets/images/user.png";
import Grace from "../assets/images/user.png";
// import Victor from "../assets/images/user.png";
import EmailIcon from "../assets/icons/mail.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import LocationIcon from "../assets/icons/location.svg";

import Training from "../assets/images/training.svg"
import Ar from "../assets/images/AR.svg"
import Design from "../assets/images/design.svg"

const calendlyLink = "https://calendly.com/braln/30min"

const navLinkList = [
    {
        name: 'Home',
        path: '/',
    },
    {
        name: 'About us',
        path: '/about',
    },
    {
        name: 'Portfolio',
        path: '/portfolio',
    },
    {
        name: 'Contact us',
        path: '/contact',
    }
]

const contactDetails = [
    {
        icon: EmailIcon,
        value: 'braln@braln.com'
    },
    {
        icon: PhoneIcon,
        value: '08033357429'
    },
    {
        icon: LocationIcon,
        value: 'Triple  Oak plaza, Peter Odili road extension, Gbalajam, Woji Portharcourt, Rivers state Nigeria'
    }
]

const footerLinkList = [
    {
        name: 'Home',
        path: '/',
    },
    {
        name: 'About Us',
        path: '/about',
    }
]

const socialFooterLinks = [
    {
        icon: Instagram,
        path: '/',
    },
    {
        icon: Dribble,
        path: '/',
    },
    {
        icon: Twitter,
        path: '/',
    },
    {
        icon: YouTube,
        path: '/',
    }
]



const team = [
    {
        name: 'Victor Edu',
        position: 'Co-Founder',
        avatar: 'https://ik.imagekit.io/braln/victor.PNG',
    },
    {
        name: 'Ofem Eteng',
        position: 'Co-Founder',
        avatar: 'https://ik.imagekit.io/braln/Ofem_Eteng_tyi_ZmdT3.jpg',
    },
    {
        name: 'Udeme Etuk',
        position: 'Co-Founder',
        avatar: Udeme,
    },
    {
        name: 'Kebe Eyong',
        position: 'Co-Founder',
        avatar: 'https://ik.imagekit.io/braln/Eyong_Kebe_PrQZ9eKQt.JPG',
    },
    {
        name: 'Rapture Godson',
        position: 'Frontend Developer',
        avatar: 'https://ik.imagekit.io/braln/rapture_y5edO212P.jpg',
    },
    {
        name: 'Nwolisah Sylvester',
        position: 'UI/UX Designer',
        avatar: Ekene,
    },
    // {
    //     name: 'Grace Kosa',
    //     position: 'Secretary',
    //     avatar: 'https://ik.imagekit.io/braln/Grace_Braln_z0MwxANWC.jpg',
    // },

]

const testimonial = [
    {
        title: 'Incredible Experience',
        description: 'We had an incredible experience working with Braln and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.',
        name: 'Uche Mike',
        position: 'Co-founder, Agromate Inc',
        avatar: CEO,
    },
    {
        title: 'Dependable, Responsive, Professional Partner',
        description: 'Fermin Apps has collaborated with Braln team for several projects such as Metaverse Apps and decentralized applications. The team is always thinking beyond the current tasks & helps us formulate a vision and possibilities for future.',
        name: 'Sophia Nonye',
        position: 'CEO, ABC Corporation',
        avatar: Grace,
    },
    {
        title: 'Incredible Experience',
        description: 'We had an incredible experience working with Braln and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.',
        name: 'Uche Mike',
        position: 'Co-founder, Agromate Inc',
        avatar: CEO,
    },
]

const specialization = [
    {
        title: 'Strategy',
        list: ['Innovation consulting', 'Research', 'Trainings'],
    },
    {
        title: 'Design',
        list: ['UI/UX design', 'Web & mobile apps', 'Prototype & Testing'],
    },
    {
        title: 'Development',
        list: ['HTML/CSS/JS', 'React/Angular', 'IOS/Android native apps'],
    },
    {
        title: 'Blockchain',
        list: ['Decentralized applications & web 3', 'Decentralized applications & web 3', 'Decentralized applications & web 3'],
    },
]

// const aboutImages = [About1, About2, About3, About4, About5, About6]
const aboutImages = ['/About1.jpg', '/About2.jpg', '/About3.jpg', '/About4.jpg', '/About5.jpg', '/About6.jpg']
const whatWeDo = [
    {
        title: 'Design and Development',
        imgSrc: Design,
        description: 'Smart methodologies and tools to employ, in the development of highly functional, intuitive, and easy-to-use mobile web apps.',
        bgColor: 'bg-[#E3F1FF]',
    },
    {
        title: 'AR/VR & Machine Learning',
        imgSrc: Ar,
        description: 'Combining virtual information with the real world for services include Multimedia, 3D-Modelling, Intelligent Interaction, Sensing and more.',
        bgColor: 'bg-[#DEF9FF]',
    },
    {
        title: 'Training and IT Consultation',
        imgSrc: Training,
        description: 'Through innovation, we generate solutions for our clients',
        bgColor: 'bg-[#E3F1FF]',
    },
]

const portfolio = [
    {
        image: "https://ik.imagekit.io/braln/iFeed_BuBR8Ndci.png",
        category: "WEB DESIGN • DEVELOPMENT",
        projectName: "ifeed charity",
        projectDescription: "iFeed Charity Network is a Non-Governmental Organization born out of the passion for the less privileged children- motherless, orphans, destitute, handicapped, physically and mentally challenged, abandoned, rejected, etc.",
        link: "https://www.ifeedcharitynetwork.org/"
    },
    {
        image: "https://ik.imagekit.io/braln/repair.png",
        category: "DESIGN • DEVELOPMENT",
        projectName: "repair circle",
        projectDescription: "At Repair Circle, we pride ourselves on delivering the best service possible for premium device repairs. From MacBook to iPhones to Dyson, we offer a doorstep repair service, so our technicians will come to fix your devices at the comfort of your home, or we offer a pickup and drop off service, servicing nationwide.",
        link: "https://repaircircle.com.au/"
    },
    {
        image: "https://ik.imagekit.io/braln/finza_Ob9MmcyuG.png",
        category: "WEB DESIGN • DEVELOPMENT",
        projectName: "finza",
        projectDescription: "Finza is everything you’ll need for safe, fast and affordable cross-border transactions",
        link: "/"
    },
    {
        image: "https://ik.imagekit.io/braln/braveheart.png",
        category: "DESIGN • DEVELOPMENT",
        projectName: "brave hearts",
        projectDescription: "We are a non-profit based organization seeking to improve the quality of living of UK armed forces Veterans by providing access to help or support of any form.",
        link: "/"
    },
    {
        image: "https://ik.imagekit.io/braln/rana.png",
        category: "DESIGN • DEVELOPMENT",
        projectName: "RANA Farms",
        projectDescription: "Our story began in 2017. Back then we were in primary production of paddy with 13 hectares of leased farmland in Gaya LGA, Kano. This was the most challenging journey of RANA Farms, which enabled us to truly understand the problems faced by smallholder farmers.",
        link: "https://www.rana.com.ng/"
    },
    {
        image: "https://ik.imagekit.io/braln/jam.png",
        category: "WEB DESIGN",
        projectName: "jam sports",
        projectDescription: "For 25 years the Sport & Social Group’s (SSG) purpose has been “connecting people through play”.  In March of 2020, the COVID19 pandemic forced all SSG sports league operations to stop.  SSG was striving to stay true to its core purpose and help bring play and connection to everyone living and working in a new remote world.",
        link: "/"
    },


]

export { calendlyLink, navLinkList, contactDetails, footerLinkList, socialFooterLinks, team, testimonial, specialization, aboutImages, whatWeDo, portfolio } 
