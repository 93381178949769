import Layout from "../components/layout"
import { calendlyLink, portfolio } from "../utils/data"
import LinkIcon from "../assets/icons/link-icon.png"

const Portfolio = () => {
    return (
        <Layout navBarColor="bg-[#F4F6FC]">
            <section className="mt-[50px] pt-[41px] bg-[#F2F3F7] capitalize md:pt-[57px] md:pb-[91px] h-[300px]  md:h-[500px] lg:h-screen xl:pt-[20vh]">
                <div className="layout-container">
                    <h1 className="text-2xl leading-[30px] font-bold text-center mb-3 text-black md:text-4xl md:mb-[26px] lg:text-[64px] lg:leading-[81px]">our projects</h1>
                    <p className="text-center max-w-xl text-[#647582] text-sm leading-[22px] pb-8 md:text-xl md:leading-[30px] md:mx-auto lg:max-w-3xl">We love projects that challenge us and force us to think out of the box. We'll never do work that's generic or follow trends blindly. We’re passionate about thoughtful projects that tell a story.</p>
                </div>
            </section>
            <div className="pb-12 md:pb-[68px] -mt-[80px] md:-mt-[200px] lg:-mt-[40vh]">
                <div className="layout-container space-y-[48px] md:grid md:grid-cols-2 md:space-y-0 md:gap-12">
                    {
                        portfolio.map((item, index) => (
                            <a href={item.link} target="_blank" rel="noreferrer" key={index} className="bg-white block rounded-2xl px-4 py-6 shadow-[0px_7px_104px_24px_rgba(195,213,232,0.3)] md:p-6">
                                <div className="mb-6 h-auto md:w-full">
                                    {/* <div className="absolute top-0 left-0 h-full w-full bg-black/30 rounded-[24px]"></div> */}
                                    <img src={item.image} alt="" className="h-full w-full object-contain" />
                                    <img src={LinkIcon} alt="" className="absolute right-2 top-2 h-12 w-12 md:h-20 md:w-20" />
                                </div>
                                <p className="text-xs leading-[30px] font-medium text-[#647582] md:text-base md:leading-[30px]">{item.category}</p>
                                <h2 className="text-black text-xl leading-[25px] font-bold capitalize my-2 md:my-[10px] md:text-2xl md:leading-[30px]">{item.projectName}</h2>
                                <p className="text-sm leading-[30px] text-[#615C66] font-normal w-11/12 md:text-base md:leading-[30px]">{item.projectDescription}</p>
                            </a>
                        ))
                    }
                </div>
            </div>

            {/* Book a call section */}
            <div className="bg-blue-bg-mobile  bg-cover bg-no-repeat pt-[34px] pb-[33px] md:bg-blue-bg md:py-[60px] md:layout-container md:rounded-3xl md:mb-[135px] lg:py-[100px]">
                <div className="layout-container">
                    <h1 className="text-white text-center text-xl leading-[25px] max-w-sm mx-auto font-bold md:text-2xl lg:text-5xl lg:max-w-4xl">Let’s work on the next big thing together.</h1>
                    <a href={calendlyLink} target="_blank" rel="noreferrer" className="text-[#0031A4] font-normal text-base leading-[23px] bg-white rounded flex items-center justify-center w-[138px] h-[49px] mt-8 mx-auto">Book A Call</a>
                </div>
            </div>
        </Layout>
    )
}

export default Portfolio