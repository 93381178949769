
const NotFound = () => {
    return (
        <div className="grid place-items-center h-screen">
            <p><span className="font-bold">404</span> | This page could not be found</p>
        </div>
    )
};

export default NotFound;
