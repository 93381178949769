import { ButtonProps } from '../../utils/typings';
import { TailSpin } from "react-loader-spinner";


const Button = ({ href, title, primary, className, isLoading, isDisabled }: ButtonProps) => {
    return href ? (
        <a className={`btn font-suisse ${primary && 'bg-primary border-none'} ${className}`} href={href}>{title}</a>
    )
        :
        (
            <button disabled={isDisabled} className={`btn font-suisse ${primary && 'bg-primary border-none'} ${className}`}>
                {isLoading ? (
                    <TailSpin height={40} width={40} color="#4452FE" />
                ) : (
                    title
                )}
            </button>
        )
};

export default Button;